
import { cleanWord } from './utils';

const concateneAllWords = (texts, currentText, indexs) => {
    let allWords = []
    Object.keys(texts).filter((t) => {
        return t !== currentText && indexs.includes(t)
    }).forEach((text) => {
        return texts[text].map((line) => {
            allWords = [...allWords, ...line.split(' ')]
            return true
        });
    });
    return allWords.map(word => cleanWord(word));
}

const getNextIndex = (texts, word, currentText, indexs) => {
    return Object.keys(texts).filter((t) => {
        return t !== currentText && indexs.includes(t)
    }).find((index) => {
        return texts[index].some((line) => {
            return line.split(" ").map((w) => cleanWord(w)).includes(word)
        })
    })
}

export {
    concateneAllWords,
    getNextIndex
}