
const motionWiggle = ({
    scaleAnimate,
    scaleHover,
    duration,
    repeatDelay,
    scale
}) => {
    return {
        initial: {},
        animate: {rotate: [0, -scaleAnimate, scaleAnimate, 0 ]},
        transition: {duration, repeat: Infinity, ease: "anticipate", repeatDelay},
        whileHover: {scale, rotate: [0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0]}
    }
}

const motionBold = ({
    duration,
    repeatDelay,
    scaleHover,
    scale
}) => {
    return {
        initial: {fontWeight: 400},
        animate: {fontWeight: [400, 900, 400]},
        transition: {duration, repeat: Infinity, ease: "anticipate", repeatDelay},
        whileHover: {scale, rotate: [0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0, 0, -scaleHover, -scaleHover, 0]}
    }
}

export {
    motionWiggle,
    motionBold
}