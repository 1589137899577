export default {
    acteur: require('../docs/acteur.txt'),
	addiction: require('../docs/addiction.txt'),
	apprehension: require('../docs/apprehension.txt'),
	avancee: require('../docs/avancee.txt'),
	blottir: require('../docs/blottir.txt'),
	bulle: require('../docs/bulle.txt'),
	choix: require('../docs/choix.txt'),
	declencheur: require('../docs/declencheur.txt'),
	derive: require('../docs/derive.txt'),
	disparition: require('../docs/disparition.txt'),
	eclipse: require('../docs/eclipse.txt'),
	ecrire: require('../docs/ecrire.txt'),
	etincelle: require('../docs/etincelle.txt'),
	fantome: require('../docs/fantome.txt'),
	funambule: require('../docs/funambule.txt'),
	gestes: require('../docs/gestes.txt'),
	gratitude: require('../docs/gratitude.txt'),
	inconnu: require('../docs/inconnu.txt'),
	independance: require('../docs/independance.txt'),
	liberation: require('../docs/liberation.txt'),
	liberte: require('../docs/liberte.txt'),
	libre: require('../docs/libre.txt'),
	loopings: require('../docs/loopings.txt'),
	naive: require('../docs/naive.txt'),
	naufrage: require('../docs/naufrage.txt'),
	papillon: require('../docs/papillon.txt'),
	perte: require('../docs/perte.txt'),
	peur: require('../docs/peur.txt'),
	quotidien: require('../docs/quotidien.txt'),
	rencontre: require('../docs/rencontre.txt'),
	reve: require('../docs/reve.txt'),
	serenite: require('../docs/serenite.txt'),
	sourire: require('../docs/sourire.txt'),
	souvenirs: require('../docs/souvenirs.txt'),
	tresor: require('../docs/tresor.txt'),
	vagues: require('../docs/vagues.txt'),
	vibrer: require('../docs/vibrer.txt'),
	vivre: require('../docs/vivre.txt')
}