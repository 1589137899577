
import { cleanWord } from './utils';
import { motion } from "framer-motion"
import { motionWiggle, motionBold, motionItalic } from './effects';

const Word = ({ words, word, index, onClickWord }) => {

    let realWord = cleanWord(word)
  
    const onClick = (e) => {
      onClickWord(e)
    }
  
    if (words && words[realWord] && realWord !== "") {
      const randRepeatDelay = Math.floor(Math.random() * 5) + 2
      const rAmplitude = Math.floor(Math.random() * 6) + 1

      return <motion.span
        key={index}
        className="cursor-pointer"
        onClick={(e) => { onClick(e) }}
        {...motionWiggle({ scaleAnimate: rAmplitude, scaleHover: 5, duration: 0.25, repeatDelay: randRepeatDelay, scale: 1.1 })}
        //{...motionBold({ duration: 1.5, repeatDelay: randRepeatDelay * 2, scaleHover: 5, scale: 1.1 })}
      >
        {word}
      </motion.span>
    }
  
    return <span key={index}>{word}</span>
}

export default Word;