
import React from "react"
import { motion } from "framer-motion"
import Line from "./Line"

const Text = React.forwardRef(({ words, text, index, currentText, onClickWord }, ref) => {
    const hidden = index !== currentText
    const initial = { opacity: hidden ? 0 : 1, zIndex: hidden ? -1 : 1000 }
    const transition = { duration: 3, ease: "anticipate" }
    
    return (
        <motion.div ref={ref} className={`absolute p-4`} key={text} initial={initial} animate={initial} transition={transition}>
        {
            text.map((line, index) => {
                return <Line key={`${index} ${line}`} line={line} words={words} onClickWord={onClickWord}/>
            })
        }
        </motion.div>
    )
})

export default Text;