const cleanWord = (word) => {
    let split = word.split("'")
    if (split.length > 1) {
      word = split[1];
    }
  
    return word.replace(/[^a-zA-Z]/g, '').toLowerCase();
}

export {
    cleanWord
}