// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
  --vh: 1vh;
}

.body {
  background-color: #f2f2f2;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vw);
  color: black;
  position: relative;
  
  font-family: "Alegreya", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,0BAA0B;EAC1B,YAAY;EACZ,kBAAkB;;EAElB,8BAA8B;EAC9B,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["\n:root {\n  --vh: 1vh;\n}\n\n.body {\n  background-color: #f2f2f2;\n  min-height: 100vh;\n  min-height: calc(var(--vh, 1vh) * 100);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(8px + 2vw);\n  color: black;\n  position: relative;\n  \n  font-family: \"Alegreya\", serif;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
