import './App.css';
import Texts from './components/docs'
import { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from "framer-motion"
import React from 'react';
import { cleanWord } from './components/utils';
import { concateneAllWords, getNextIndex } from './components/wordUtils';
import Text from './components/Text';
import { motionWiggle } from './components/effects';

async function fetchData(setTexts, setIndexs) {
  let allPromise = Object.keys(Texts).map(index => {
    return fetch(Texts[index])
      .then(response => response.text())
      .then(data => {
        return [index, data.split('\n')];
      });
  })

  await Promise.all(allPromise).then((data) => {
    let newArr = []
    data.forEach((d) => {
      if (d) {
        newArr[d[0]] = d[1]
      }
    })
    setTexts(newArr);
    setIndexs(Object.keys(newArr));
  });
}

function App() {
  const [texts, setTexts] = useState(null);
  const [indexs, setIndexs] = useState(null);
  const [words, setWords] = useState(null);
  const [countWords, setCountWords] = useState(null);
  const [currentText, setCurrentText] = useState(null);

  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [ghostWord, setGhostWord] = useState(null)

  const displayRef = useRef([])

  const updateWords = useCallback((currentText) => {
    let words = concateneAllWords(texts, currentText, indexs)
    let obj = {};
    words.forEach(word => { obj[word] = obj[word] ? obj[word] + 1 : 1 })

    if (indexs.includes(currentText) && indexs.length > 1){
      setIndexs(indexs.filter((i) => i !== currentText))
    }
    setCountWords(obj)
    setWords(words);
  }, [texts, indexs])

  useEffect(() => {
    if (texts == null) {
      fetchData(setTexts, setIndexs)
    }
    if (currentText != null && texts != null) {
      updateWords(currentText)
    }
  }, [currentText, texts, updateWords]);

  useEffect(() => {
    if (texts != null && words == null) {
      let randomKey = Object.keys(texts)[Math.floor(Math.random() * Object.keys(texts).length)]
      let currentText = texts[randomKey]
      updateWords(currentText)
      setCurrentText(randomKey)
    }
  }, [words, texts, updateWords]);

  useEffect(() => {
    const appHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', appHeight);
    appHeight();
  
    return () => {
      window.removeEventListener('resize', appHeight);
    }
  }, []); 
  
  const onClickWord = (event) => {
    let word = cleanWord(event.target.innerText)
    let wordPos = event.target.getBoundingClientRect()
    let index = getNextIndex(texts, word, currentText, indexs)

    const containerTo = displayRef.current[index]
    const allElements = containerTo.querySelectorAll('span')
    const targetSpan = Array.from(allElements).find(span => cleanWord(span.textContent) === word);
    let wordToPos = targetSpan.getBoundingClientRect()

    setGhostWord({ word: targetSpan.innerText, index: index })
    setFrom({ x: wordPos.x, y: wordPos.y })
    setTo({ x: wordToPos.x, y: wordToPos.y })
    setCurrentText(index)
  }

  return (
    <div className="body">
      {
        texts && Object.keys(texts).map((text) => {
          return <Text
            ref={el => displayRef.current[text] = el}
            text={texts[text]}
            index={text}
            key={text}
            currentText={currentText}
            words={countWords}
            onClickWord={onClickWord}
          />
        })
      }
      {
        ghostWord && from && to && <motion.div
          className="absolute"
          initial={{ top: from.y, left: from.x, opacity: 0.8}}
          animate={{ top: to.y, left: to.x, opacity: 0.6}}
          transition={{ duration: 1.6, ease: "anticipate" }}
          onAnimationComplete={() => {
            setGhostWord(null)
            setFrom(null)
            setTo(null)
          }}
        >
            {ghostWord.word}
        </motion.div>
      }
      {
        indexs && texts &&
          <motion.div
            className='absolute bottom-5 text-xl'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 5, ease: "anticipate" }}
          >
            {indexs.length} / {Object.keys(texts).length}
          </motion.div>
      }
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 5, ease: "anticipate" }}
        className='absolute bottom-5 right-10 italic font-semibold md:text-3xl'
      >
        <motion.div
          {...motionWiggle({ scaleAnimate: 3, scaleHover: 2, duration: 0.25, repeatDelay: 5, scale: 1 })}
        >
          <a href='https://www.instagram.com/margotobjois' target='blank'>
            margot objois
          </a>
        </motion.div>
        
      </motion.div>
    </div>
  );
}

export default App;
