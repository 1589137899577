
import Word from './Word';

const Line = ({ words, line, index, onClickWord }) => {
    if (line === '') {
      return <br key={index}></br>
    }
  
    return (<div className="flex gap-[0.6vw] flex-wrap w-100">
      {
        line.split(" ").map((word, index) => {
          return <Word words={words} key={index} word={word} onClickWord={onClickWord}/>
        })
      }
      </div>
    )
}

export default Line;